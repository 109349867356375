import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import ThankYouContainer from 'components/ThankYouContainer/ThankYouContainer';
import PreFooter from 'components/PreFooter/PreFooter';

const ThankYouPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.thankYouYaml.title} />
      <Hero data={data.thankYouYaml.hero} />
      <ThankYouContainer data={data.thankYouYaml.thankYouContainer} />
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default ThankYouPage;

export const pageQuery = graphql`
  query ThankYouPageQuery {
    thankYouYaml {
      id
      title

      hero {
        size
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }

      thankYouContainer {
        title
        description
      }
    }
  }
`;
