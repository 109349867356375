import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './ThankYouContainer.scss';

const ThankYouContainer = ({ data }) => (
  <div className="aaa-thank-you">
    <ContentBox>
      <h2 className="aaa-thank-you__title">{data.title}</h2>
      <div
        className="aaa-thank-you__description"
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
    </ContentBox>
  </div>
);

export default ThankYouContainer;
